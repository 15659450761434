export default [
  {
    key: 'stockLocationId',
    label: 'field.location',
    type: 'nAsynSingleSelection',
    repository: 'stockLocation',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'code',
    label: 'field.binCode',
    type: 'text',
    operator: '%',
  },
];